import { CancelModalClickValues, CloseModalTriggers } from '../../types';
import { CreateActionParams } from '../actions';
import { logCancelBi } from '../../api/implementations/logCancelBi/logCancelBi';

export type CloseModal = (triggeredBy?: CloseModalTriggers) => void;

export function createCloseModalAction({
  context,
  getControllerState,
}: CreateActionParams): CloseModal {
  return (triggeredBy) => {
    const [state, setState] = getControllerState();
    const { biLogger } = context;

    if (biLogger) {
      if (triggeredBy === CloseModalTriggers.CloseModal) {
        logCancelBi({
          biLogger,
          selectedTab: state.selectedTab.type,
          clickValue: CancelModalClickValues.ExitModal,
        });
      }
    }

    setState({ modalContext: undefined });
  };
}
